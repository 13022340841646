import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import style from "./contact.module.css"

class Contact extends Component {
  state = {}

  render() {
    return (
      <Layout>
        <SEO
          title="Contact"
          description="Official Website for Web Developer and Photographer, Andreas Karpasitis, based in Barcelona. Photography and Web Development Portfolio."
          keywords="Contact, Email, Barcelona, Karpasitis"
          url="https://www.karpasitis.net/contact"
        />
        <div className={style.container}>
          <h3 className={style.text}>
            Looking forward to hear about new creative projects, collaborations,
            ideas and opportunities. Feel free to send me an email!
          </h3>
          <a href="mailto:info@karpasitis.net">
            <button className={style.button}>Send Email</button>
          </a>
          <a href="mailto:info@karpasitis.net" className={style.text}>
            info@karpasitis.net
          </a>
          <h4 className={style.text}>
            <b>Current Location:</b>
            <em> Barcelona, Catalonia, Spain</em>
          </h4>
        </div>
      </Layout>
    )
  }
}

export default Contact
